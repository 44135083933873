import { template as template_4371c7cff59a488fb5a7ec16f84662d1 } from "@ember/template-compiler";
const FKText = template_4371c7cff59a488fb5a7ec16f84662d1(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
